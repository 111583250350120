<template>
  <v-row no-gutter>
    <AddPaymentForm v-if="hasComponentPermission('LM_ADDPAYMENT')" @paymentDetailsChanged="updatePaymentsDtKey" />
    <v-col v-if="store.state.user.currentUser.isClient || store.state.user.currentUser.isVendor">
      <v-card>
<v-btn-toggle v-model="selectedTab">
        <v-btn text :value="1" small x-small>
          <v-icon color="warning">{{ icons.mdiCalendarCheckOutline }} </v-icon> Upcoming
        </v-btn>

        <v-btn text :value="2" small x-small>
         <v-icon color="primary">{{ icons.mdiClockOutline }} </v-icon> To Do
        </v-btn>

        <v-btn :value="3" small text x-small>
          <v-icon color="success">{{ icons.mdiCheckboxMarkedCircleOutline }}</v-icon> Completed
        </v-btn>
      </v-btn-toggle>
      </v-card>
        <Transactions v-if="selectedTab==1" :key="transactionDtKey" :filter="['Issued', 'Scheduled', 'Failed']" />
          <PendingInvoice v-if="selectedTab==2" :key="paymentDtKey" />
          <Transactions  v-if="selectedTab==3" :key="transactionDtKey" :filter="['Passed']" />
    </v-col>
    <template v-if="store.state.user.currentUser.isAdmin">
      <v-col cols="12">
        <datatable :key="paymentDtKey" @editPayment="editPayment" @filterTransactions="transactionsFilterApplied">
        </datatable>
      </v-col>
      <component
        v-bind:is="editComponent"
        @paymentDetailsChanged="updatePaymentsDtKey"
        @transactionDetailsChanged="updateTransactionDtKey"
        v-if="editComponent && editObj != {}"
        :obj="editObj"
      >
      </component>
      <v-col cols="12">
        <AddTransactionForm :sellerInvoiceNo="sellerInvoiceNo" @transactionDetailsChanged="updateTransactionDtKey" />
      </v-col>
      <v-col cols="12">
        <TransactionsDatatable
          :key="transactionDtKey"
          @editTransaction="editTransaction"
          :sellerInvoiceNo="sellerInvoiceNo"
        ></TransactionsDatatable>
      </v-col>
      <v-col cols="12">
        <UnsettledAmount></UnsettledAmount>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import {
  mdiCheckboxMarkedCircleOutline,
  mdiCalendarCheckOutline,
  mdiClockOutline,
  mdiChevronRight,
  mdiChevronLeft,
} from '@mdi/js'

import Datatable from './Datatable'
import PendingInvoice from './PendingInvoice'
import Transactions from './Transactions'
import TransactionsDatatable from '@/views/transaction/Datatable'
import AddPaymentForm from './AddPaymentForm'
import AddTransactionForm from '@/views/transaction/AddTransactionForm'
import UnsettledAmount from '@/views/transaction/UnsettledAmount'
import { ref, reactive } from '@vue/composition-api'
import Util from '@/utils/axios-util.js'
import { config } from '@/constants/config.js'
import moment from 'moment'
import store from '@/store' // your vuex store
// import AddPaymentForm from './AddPaymentForm.vue'

export default {
  components: {
    Datatable,
    TransactionsDatatable,
    AddPaymentForm,
    AddTransactionForm,
    UnsettledAmount,
    PendingInvoice,
    Transactions,
  },
  setup() {
    const paymentDtKey = ''
    const transactionDtKey = ''
    const sellerInvoiceNo = ''
    let editObj = reactive({})
    const editComponent = ref('')

    return {
      store: store,
      selectedTab: 1,
      paymentDtKey,
      transactionDtKey,
      sellerInvoiceNo,
      editObj,
      editComponent,
      icons: {
        mdiCheckboxMarkedCircleOutline,
        mdiCalendarCheckOutline,
        mdiClockOutline,
        mdiChevronRight,
        mdiChevronLeft,
      },
    }
  },
  methods: {
    updatePaymentsDtKey: function() {
      let e = this
      e.editComponent = '';
      e.paymentDtKey = e.GenerateRandomKey(4)
    },
    updateTransactionDtKey: function() {
      let e = this
      e.transactionDtKey = e.GenerateRandomKey(4)
    },
    transactionsFilterApplied: function(sellerInvoiceNo) {
      let e = this
      e.sellerInvoiceNo = sellerInvoiceNo
      e.transactionDtKey = e.GenerateRandomKey(4)
      document.getElementById('transactions').scrollIntoView()
      //e.paymentDtKey = e.GenerateRandomKey(4)
    },
    editPayment: function(id) {
      let e = this
      if (!id) {
        e.errorMessage('Invalid payment selected.')
        return false
      }
      e.editComponent = ''
      Util.Axios.get(`/payment/${id}/get-details`)
        .then(function(response) {
          if (response.data.succeeded) {
            e.editObj = response.data.data
            e.editObj.metaData.invoiceDate = moment(response.data.data.metaData.invoiceDate).format(config.dateValueFormat)
            e.editComponent = 'AddPaymentForm'
            e.editComponentKey = e.GenerateRandomKey(5)
          } else e.errorMessage('Something went wrong!')
        })
        .catch(function(response) {})
        .finally(function(response) {})
    },
    editTransaction: function(obj) {
      let e = this
      if (!obj.id) {
        e.errorMessage('Invalid transaction selected.')
        return false
      }
      e.editComponent = ''
      Util.Axios.get(`/payment/transaction/${obj.id}/get-details`)
        .then(function(response) {
          if (response.data.succeeded) {
            e.editObj = reactive(response.data.data)
            e.editObj.code = obj.code
            e.editObj.paymentId = response.data.data.payment.id
            e.editObj.transactionAt = moment(response.data.data.transactionAt).format(config.dateValueFormat)
            e.editComponent = 'AddTransactionForm'
            e.editComponentKey = e.GenerateRandomKey(5)
          } else e.errorMessage('Something went wrong!')
        })
        .catch(function(response) {})
        .finally(function(response) {})
    },
  },
  watch: {
    selectedTab: function(val) {
      let e = this
      if (val == 1 || val == 2) {
        e.transactionDtKey = e.GenerateRandomKey(4)
      }
    },
  },
}
</script>
