<template>
  <v-col class="text-right" cols="12">
    <v-btn color="primary" v-if="!addPayment.id" dark v-on:click="dialog = true">
      <v-icon left>{{ icons.mdiPlus }}</v-icon
      >Add New Invoice
    </v-btn>
    <v-dialog v-model="dialog" max-width="650px">
      <v-card>
        <v-card-title>{{ !addPayment.id ? 'Add New Invoice' : 'Edit Invoice' }}</v-card-title>
        <v-card-text>
          <!-- <v-container>
              <v-row>
                <v-col cols="12"> -->
          <v-form ref="form" v-model="isValid" lazy-validation>
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <label>Select Vendor</label>
                <v-select
                  :items="vendorsList"
                  item-text="value"
                  item-value="key"
                  outlined
                  dense
                  v-model="addPayment.vendorId"
                  :rules="numberRules"
                  v-on:change="getCustomers"
                ></v-select>
                <label>Invoice No</label>
                <v-text-field
                  v-model="addPayment.sellerInvoiceNo"
                  type="text"
                  outlined
                  :disabled="addPayment.id && addPayment.id != null"
                  dense
                  placeholder="E.g. ABCD1234124"
                  :rules="nameRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <label>Select Customer</label>
                <v-select
                  :items="customersList"
                  item-text="value"
                  item-value="key"
                  outlined
                  :disabled="addPayment.id && addPayment.id != null"
                  dense
                  v-model="addPayment.customerId"
                  :rules="numberRules"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <!-- <v-col cols="12" sm="12" md="6">
                        <label>Frequency</label>
                        <v-select
                          :items="['Monthly', 'Weekly']"
                          outlined
                          dense
                          v-model="addPayment.frequency"
                          :rules="nameRules"
                          v-on:change="calculateTransactionAmount"
                        ></v-select>
                      </v-col> -->

              <v-col cols="12" sm="12" md="6">
                <label>Invoice Date</label>
                <v-menu v-model="invoiceDatePopup" :close-on-content-click="false" transition="scale-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="computedDateFormattedMomentjs"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                      :rules="dateRules"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="addPayment.metaData.invoiceDate"
                    @input="invoiceDatePopup = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="12" md="6">
                <label>Total amount (INR)</label>
                <v-text-field
                  v-model="addPayment.metaData.totalAmount.amount"
                  type="number"
                  outlined
                  v-on:change="calculateTransactionAmount"
                  dense
                  placeholder="E.g. 1000"
                  :rules="numberRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" v-if="store.state.user.currentUser.isAdmin">
                <label>No Of Installments:</label>
                <v-text-field
                  v-model="addPayment.metaData.noOfInstallments"
                  type="number"
                  outlined
                  v-on:change="calculateTransactionAmount"
                  dense
                  max-length="2"
                  placeholder="E.g. 10, 20"
                  :rules="numberRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <p v-if="!store.state.user.currentUser.isAdmin">
                  No Of Installments:&nbsp;&nbsp;{{ addPayment.metaData.noOfInstallments }}</p>
                <p>
                  Installment Amount:&nbsp;&nbsp;{{
                    addPayment.amountPerTransaction.amount | format_money(addPayment.amountPerTransaction.currency)
                  }}
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col offset-md="5" cols="12">
                <v-btn color="primary" v-on:click="savePayment" :loading="loading" :disabled="loading">
                  Save
                </v-btn>
                &nbsp;&nbsp;
                <v-btn color="light" @click="dialog = false">Cancel</v-btn>
              </v-col>
            </v-row>
          </v-form>
          <!-- </v-col>
              </v-row>
            </v-container> -->
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import { mdiPlus, mdicalendar } from '@mdi/js'
import { ref, reactive } from '@vue/composition-api'
import Util from '@/utils/axios-util.js'
import { config } from '@/constants/config.js'
import moment from 'moment'
import store from '@/store' // your vuex store

export default {
  data() {
    return {
      dialog: false,
      addPayment: {
        sellerInvoiceNo: '',
        customerId: '',
        vendorId: '',
        amountPerTransaction: {
          amount: '',
          currency: 'INR',
        },
        frequency: 'Weekly',
        paymentDate: '',
        metaData: {
          invoiceDate: '',
          totalAmount: {
            amount: '',
            currency: 'INR',
          },
          noOfInstallments: 10,
        },
      },
      isValid: false,
      loading: false,
      nameRules: [
        v => !!v || 'Value is required.',
        v => (v && v.length > 4) || 'Value must contain minimum 3 characters.',
      ],
      numberRules: [v => !!v || 'Value is required.'],
      dateRules: [v => !!v || 'Invoice Date is required.'],
      invoiceDatePopup: false,
      customersList: [],
      vendorsList: [],
      store:store
    }
  },
  setup() {
    return {
      icons: {
        mdiPlus,
        mdicalendar,
      },
    }
  },
  props: {
    obj: {
      default() {
        return {}
      },
      type: Object,
    },
  },
  mounted() {
    let e = this
    e.getVendors()
    if (e.obj != {} && e.obj.id) {
      e.addPayment = Object.assign({}, e.obj)
      e.getCustomers()
      e.dialog = true
    }
  },
  methods: {
    savePayment: function() {
      let e = this
      let isValidated = e.$refs.form.validate()
      if (isValidated) {
        e.loading = true
        Util.Axios.post(e.addPayment.id ? `/payment/${e.addPayment.id}/update` : '/payment/add-new', e.addPayment)
          .then(function(response) {
            if (response.data.succeeded) {
              e.successMessage('Payment details saved successfully.')
              e.dialog = false
              e.addPayment = {
                sellerInvoiceNo: '',
                vendorId: '',
                customerId: '',
                amountPerTransaction: {
                  amount: '',
                  currency: 'INR',
                },
                frequency: 'Weekly',
                paymentDate: '',
                metaData: {
                  invoiceDate: '',
                  totalAmount: {
                    amount: '',
                    currency: 'INR',
                  },
                  noOfInstallments: 10,
                },
              }
              e.$emit('paymentDetailsChanged')
            } else {
              e.errorMessage(e.generateErrorMessage(response.errors))
            }
          })
          .catch(function(response) {})
          .finally(function(response) {
            e.loading = false
          })
      }
    },
    getVendors: function() {
      let e = this
      Util.Axios.get('/network/vendor/get-ddl', { params: { self: true } })
        .then(function(response) {
          e.vendorsList = response.data.data.items
          if (e.vendorsList && e.vendorsList.length && e.vendorsList.length == 1) {
            e.addPayment.vendorId = e.vendorsList[0].key
            e.getCustomers()
          }
        })
        .catch(function(response) {})
        .finally(function(response) {})
    },
    getCustomers: function() {
      let e = this
      e.customersList = []
      if (!e.addPayment.vendorId) return false
      Util.Axios.get('/customer/get-customers-ddl', {
        params: {
          vendorIds: [parseInt(e.addPayment.vendorId)],
        },
      })
        .then(function(response) {
          e.customersList = response.data.data.items
        })
        .catch(function(response) {})
        .finally(function(response) {})
    },
    calculateTransactionAmount: function() {
      let e = this
      let noOfInstallments = parseInt(e.addPayment.metaData.noOfInstallments)
      let totalAmount = e.addPayment.metaData.totalAmount.amount
      if (noOfInstallments && totalAmount) {
        e.addPayment.amountPerTransaction.amount = parseFloat(totalAmount / noOfInstallments)
      }
    },
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.addPayment.metaData.invoiceDate
        ? moment(this.addPayment.metaData.invoiceDate).format(config.dateFormat)
        : ''
    },
  },
}
</script>
