<template>
  <v-row>
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="transactions.items"
        item-key="full_name"
        :class="datatableOptions.class"
        :mobile-breakpoint="datatableOptions.mobileBreakpoint"
        :dense="datatableOptions.dense"
        :loading="loading"
        :loading-text="datatableOptions.loadingText"
      >
        <template #[`item.payment.sellerInvoiceNo`]="{ item }">
          <a v-on:click="showDetails(item.id)" :title="item.payment.sellerInvoiceNo">
            {{ item.payment.sellerInvoiceNo }}
          </a>
        </template>
        <template #[`item.amount.amount`]="{ item }">
          {{ item.amount.amount | format_money(item.amount.currency) }}
        </template>

        <template #[`item.transactionAt`]="{ item }">
          {{ item.transactionAt | format_date_local_timezone }}
        </template>
      </v-data-table>

      <v-dialog v-model="showDetailsDialog" max-width="600px">
        <v-card>
          <v-card-title class="text-h5"> Transaction Details </v-card-title>

          <v-card-text>
            <v-row v-if="!showDetailsLoading">
              <v-col cols="12">
                <v-list>
                  <v-list-item>
                    Invoice No: {{ transactionsDetails.payment ? transactionsDetails.payment.sellerInvoiceNo : '' }}
                  </v-list-item>
                  <v-list-item>
                    Customer: {{ transactionsDetails.customer ? transactionsDetails.customer.companyName : '' }}
                  </v-list-item>
                  <v-list-item>
                    Vendor:
                    {{
                      transactionsDetails.payment
                        ? transactionsDetails.payment.vendor.companyName +
                          ': ' +
                          transactionsDetails.payment.vendor.name
                        : ''
                    }}
                  </v-list-item>
                  <v-list-item v-if="transactionsDetails.amount">
                    Total Amount:
                    {{ transactionsDetails.amount.amount | format_money(transactionsDetails.amount.currency) }}
                  </v-list-item>
                  <v-list-item v-if="transactionsDetails.status">
                    Status: {{ transactionsDetails.status }}
                  </v-list-item>
                  <v-list-item v-if="transactionsDetails.transactionAt">
                    Due Date: {{ transactionsDetails.transactionAt | format_date_local_timezone }}
                  </v-list-item>
                  <!-- <v-list-item> Mandate: {{ transactionsDetails.sellerInvoiceNo }} </v-list-item> -->
                </v-list>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12" class="text-center">
                <v-progress-circular :width="3" indeterminate></v-progress-circular> Loading
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="showDetailsDialog = false"> Ok</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { mdiCloseCircleOutline, mdiCheckboxMarkedCircleOutline } from '@mdi/js'
import Util from '@/utils/axios-util.js'
import { ref, reactive } from '@vue/composition-api'

export default {
  data() {
    return {
      options: {},
    }
  },
  setup() {
    const showDetailsDialog = ref(false)
    const showDetailsLoading = ref(false)
    const transactions = reactive({})
    const deleteObj = reactive({})
    return {
      headers: [
        { text: 'Date', value: 'transactionAt' },
        { text: 'Invoice No', value: 'payment.sellerInvoiceNo', sortable: true },
        { text: 'Vendor', value: 'payment.vendor.companyName', sortable: false },
        // { text: 'Contact Person', value: 'customer.name' },
        // { text: 'Frequency', value: 'frequency' },
        { text: 'Amount', value: 'amount.amount', sortable: false, class: 'text-end', cellClass: 'text-end' },
        // { text: 'Installment Amount', value: 'amountPerTransaction.amount' },
        // { text: 'Total Amount Received', value: 'amountReceived.amount' },
        // { text: 'Mandate Value', value: 'customer.activeMandate.maxAmount.amount' },
        // { text: 'Next Installment Date', value: 'nextInstallmentDate' },

        // { text: 'Status', value: 'status' },
        // { text: '', value: 'action' },
      ],
      transactions,
      loading: false,
      // icons
      icons: {
        mdiCheckboxMarkedCircleOutline,
        mdiCloseCircleOutline,
      },
      showDetailsDialog,
      deleteObj,
      showDetailsLoading,
      showTransactionsLoading: false,
      transactionsDetails: {},
    }
  },
  mounted() {
    let e = this
    e.getData()
  },
  props: {
    filter: {
      type: Array,
      required: false,
    },
  },
  watch: {
    options: {
      handler(newVal) {
        this.getData()
      },
      deep: true,
    },
  },
  methods: {
    getData: function() {
      let e = this
      e.loading = true
      let sortOrder = e.options.sortDesc ? 'DESC' : 'ASC'
      Util.Axios.get('/payment/get-paged-transactions-accessible-by-role', {
        params: {
          Statuses: e.filter && e.filter.length ? e.filter : [],
          //     FromPageNumber: 1,
          //     PageNumber: e.options.page,
          //     PageSize: e.options.itemsPerPage,
          //     SearchText: '',
          //     SortOrder: e.options.sortBy && e.options.sortBy.length ? `${e.options.sortBy[0]}|${sortOrder}` : '',
          //     CustomerApprovalStatus: '["Pending"]',
        },
      })
        .then(function(response) {
          e.transactions = response.data.data
        })
        .catch(function(response) {})
        .finally(function(response) {
          e.loading = false
        })
    },
    changePaymentStatus: function(data) {
      let e = this
    },
    showDetails: function(id) {
      let e = this
      if (id) {
        e.showDetailsLoading = true
        // get payment details
        Util.Axios.get(`/Payment/transaction/${id}/get-details`)
          .then(function(response) {
            if (response.data.succeeded) {
              e.transactionsDetails = response.data.data
            } else {
              e.errorMessage(e.generateErrorMessage(response.data.errors))
            }
          })
          .catch(function(response) {})
          .finally(function(response) {
            e.showDetailsLoading = false
          })
        // get payment transactions
        //e.showTransactionsLoading = false
        // Util.Axios.get(`/Payment/get-paged-transactions`)
        //   .then(function (response) {
        //     if (response.data.succeeded) {
        //       e.transactions = response.data.data
        //     } else {
        //       e.errorMessage(e.generateErrorMessage(response.data.errors))
        //     }
        //   })
        //   .catch(function (response) { })
        //   .finally(function (response) {
        //     e.showTransactionsLoading = false
        //   })
        e.showDetailsDialog = true
      } else {
        e.errorMessage('Invalid Payment selected.')
      }
    },
    deletePaymentConfirmation: function(id) {
      let e = this
      if (id) {
        e.deleteObj = e.paymentsList.filter(p => p.id == id)[0]
      } else {
        e.errorMessage('Invalid payment selected.')
      }
    },
  },
}
</script>
