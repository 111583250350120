<template>
  <v-card>
    <v-card-title> Invoices </v-card-title>
    <v-data-table
      :headers="headers"
      :items="paymentsList"
      item-key="full_name"
      class="table-rounded"
      dense
      :loading="loading"
      loading-text="Loading... Please wait"
    >
      <!-- name -->
      <!-- <template #[`item.full_name`]="{ item }">
				<div class="d-flex flex-column">
					<span class="d-block font-weight-semibold text--primary text-truncate">{{ item.full_name }}</span>
					<small>{{ item.post }}</small>
				</div>
			</template>
			<template #[`item.transaction_no`]="{ item }">
				{{ item.transaction_no }} / {{ item.total_transaction }}
			</template> -->
      <!-- status -->
      <!-- <template #[`item.status`]="{ item }">
				<v-chip small :color="statusColor[status[item.status]]" class="font-weight-medium">
					{{ status[item.status] }}
				</v-chip>
			</template> -->
      <template #[`item.metaData.totalAmount.amount`]="{ item }">
        {{ item.metaData.totalAmount.amount | format_money(item.metaData.totalAmount.currency) }}

        <!--/<br /> {{ item.amountPerTransaction.amount }} -->
      </template>

      <template #[`item.amountReceived.amount`]="{ item }">
        {{ item.amountReceived.amount | format_money(item.amountReceived.currency) }}
      </template>
      <template #[`item.customer.activeMandate.maxAmount.amount`]="{ item }">
        <template v-if="item.customer.activeMandate">
          {{
            item.customer.activeMandate.maxAmount.amount | format_money(item.customer.activeMandate.maxAmount.currency)
          }}
        </template>
      </template>
      <template #[`item.nextInstallmentDate`]="{ item }">
        {{ item.nextInstallmentDate | format_date }}
      </template>
      <template #[`item.customer.activeMandate.expiresAt`]="{ item }">
        <template v-if="item.customer.activeMandate">
          {{ item.customer.activeMandate.expiresAt | format_date_local_timezone }}
        </template>
      </template>
      <template #[`item.status`]="{ item }">
        <v-chip
          small
          :color="
            item.status == 'Completed'
              ? 'success'
              : item.status == 'Aborted'
              ? 'error'
              : item.status == 'Partially Complete'
              ? 'warning'
              : ''
          "
        >
          {{ item.status }}
        </v-chip>
      </template>
      <template #[`item.action`]="{ item }">
        <v-btn-toggle>
          <v-btn x-small v-on:click="editPayment(item.id)" title="Edit Payment">
            <v-icon>{{ icons.mdiCircleEditOutline }}</v-icon>
          </v-btn>
          <v-btn x-small v-on:click="deletePaymentConfirmation(item.id)" title="Delete Payment">
            <v-icon>{{ icons.mdiDeleteCircleOutline }}</v-icon>
          </v-btn>
          <v-btn
            color="primary"
            small
            outlined
            v-on:click="showTransactions(item.sellerInvoiceNo)"
            title="Show transactons"
            ><v-icon>{{ icons.mdiFormatListChecks }}</v-icon>
          </v-btn>
        </v-btn-toggle>
      </template>
    </v-data-table>

    <v-dialog v-model="deleteDialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Are you sure? </v-card-title>

        <v-card-text v-if="deleteObj.id">
          Do you want to delete payment of amount
          <b> {{ deleteObj.metaData.totalAmount.amount | format_money(deleteObj.metaData.totalAmount.currency) }} .</b>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn @click="deleteDialog = false"> Cancel </v-btn>

          <v-btn color="error" :loading="deleteLoading" :disabled="deleteLoading" v-on:click="deletePayment">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiCircleEditOutline,
  mdiFormatListChecks,
  mdiDeleteCircleOutline,
  mdiSend,
} from '@mdi/js'
import Util from '@/utils/axios-util.js'
import { ref, reactive } from '@vue/composition-api'

export default {
  setup() {
    const loading = ref(false)
    const deleteDialog = ref(false)
    const deleteLoading = ref(false)
    const paymentsList = ref([])
    const deleteObj = reactive({})
    return {
      headers: [
        { text: 'Company', value: 'customer.companyName' },
        // { text: 'Contact Person', value: 'customer.name' },
        { text: 'Invoice No', value: 'sellerInvoiceNo' },
        // { text: 'Frequency', value: 'frequency' },
        { text: 'Invoice Value', value: 'metaData.totalAmount.amount' },
        // { text: 'Installment Amount', value: 'amountPerTransaction.amount' },
        { text: 'Total Amount Received', value: 'amountReceived.amount' },
        { text: 'Mandate Value', value: 'customer.activeMandate.maxAmount.amount' },
        { text: 'Next Installment Date', value: 'nextInstallmentDate' },
        { text: 'Mandate Expiry', value: 'customer.activeMandate.expiresAt' },
        { text: 'Status', value: 'status' },
        { text: '', value: 'action' },
      ],
      paymentsList,
      loading,
      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiCircleEditOutline,
        mdiDeleteCircleOutline,
        mdiSend,
        mdiFormatListChecks,
      },
      deleteDialog,
      deleteObj,
      deleteLoading,
    }
  },
  mounted() {
    let e = this
    e.getData()
  },
  methods: {
    getData: function() {
      let e = this
      e.loading = true
      Util.Axios.get('/payment/get-paged-payments')
        .then(function(response) {
          e.paymentsList = response.data.data.items
        })
        .catch(function(response) {})
        .finally(function(response) {
          e.loading = false
        })
    },
    addPayment: function(data) {
      let e = this
      e.$emit('addPayment', data)
    },
    showTransactions(id) {
      this.$emit('filterTransactions', id)
    },
    editPayment: function(id) {
      this.$emit('editPayment', id)
    },
    deletePayment: function() {
      let e = this
      if (e.deleteObj.id) {
        e.deleteLoading = true
        Util.Axios.delete(`/Payment/${e.deleteObj.id}/delete`)
          .then(function(response) {
            if (response.data.succeeded) {
              e.successMessage('Payment Deleted successfully.')
              e.getData()
              e.deleteDialog = false
            } else {
              e.errorMessage(e.generateErrorMessage(response.data.errors))
            }
          })
          .catch(function(response) {})
          .finally(function(response) {
            e.deleteLoading = false
          })
      } else {
        e.errorMessage('Invalid Payment selected.')
      }
    },
    deletePaymentConfirmation: function(id) {
      let e = this
      if (id) {
        e.deleteObj = e.paymentsList.filter(p => p.id == id)[0]
        e.deleteDialog = true
      } else {
        e.errorMessage('Invalid payment selected.')
      }
    },
  },
}
</script>
