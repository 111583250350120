<template>
  <div :key="paymentDtKey">
    <v-data-table
      :headers="headers"
      :items="payments.items"
      item-key="full_name"
      :class="datatableOptions.class"
      :mobile-breakpoint="datatableOptions.mobileBreakpoint"
      :dense="datatableOptions.dense"
      :loading="loading"
      :loading-text="datatableOptions.loadingText"
      :options.sync="options"
      :server-items-length="payments.totalItems"
    >
      <!-- name -->
      <!-- <template #[`item.full_name`]="{ item }">
				<div class="d-flex flex-column">
					<span class="d-block font-weight-semibold text--primary text-truncate">{{ item.full_name }}</span>
					<small>{{ item.post }}</small>
				</div>
			</template>
			<template #[`item.transaction_no`]="{ item }">
				{{ item.transaction_no }} / {{ item.total_transaction }}
			</template> -->
      <!-- status -->
      <!-- <template #[`item.status`]="{ item }">
				<v-chip small :color="statusColor[status[item.status]]" class="font-weight-medium">
					{{ status[item.status] }}
				</v-chip>
			</template> -->
      <template #[`item.sellerInvoiceNo`]="{ item }">
        <a v-on:click="showDetails(item.id)" :title="item.sellerInvoiceNo">
          {{ item.sellerInvoiceNo }}
        </a>
        <!--/<br /> {{ item.amountPerTransaction.amount }} -->
      </template>
      <template #[`item.metaData.totalAmount.amount`]="{ item }">
        {{ item.metaData.totalAmount.amount | format_money(item.metaData.totalAmount.currency) }}

        <!--/<br /> {{ item.amountPerTransaction.amount }} -->
      </template>

      <template #[`item.amountReceived.amount`]="{ item }">
        {{ item.amountReceived.amount | format_money(item.amountReceived.currency) }}
      </template>
      <template #[`item.customer.activeMandate.maxAmount.amount`]="{ item }">
        <template v-if="item.customer.activeMandate">
          {{
            item.customer.activeMandate.maxAmount.amount | format_money(item.customer.activeMandate.maxAmount.currency)
          }}
        </template>
      </template>
      <template #[`item.nextInstallmentDate`]="{ item }">
        {{ item.nextInstallmentDate | format_date }}
      </template>
      <template #[`item.customer.activeMandate.expiresAt`]="{ item }">
        <template v-if="item.customer.activeMandate">
          {{ item.customer.activeMandate.expiresAt | format_date_local_timezone }}
        </template>
      </template>
      <template #[`item.status`]="{ item }">
        <v-chip
          small
          :color="
            item.status == 'Completed'
              ? 'success'
              : item.status == 'Aborted'
              ? 'error'
              : item.status == 'Partially Complete'
              ? 'warning'
              : ''
          "
        >
          {{ item.status }}
        </v-chip>
      </template>
      <template #[`item.action`]="{ item }">
        <v-btn-toggle v-if="item.customerApprovalStatus != 'Accepted' && item.customerApprovalStatus != 'Rejected'">
          <v-btn
            x-small
            v-if="hasComponentPermission('LM_UPDATEPAYMENTSCUSTOMERAPPROVALSTATUS') && !item.forVendor"
            v-on:click="changePaymentStatusConfirmation(item.id, true)"
            type="success"
            title="Accept"
          >
            <v-icon color="success">{{ icons.mdiCheckboxMarkedCircleOutline }}</v-icon
            >&nbsp;Accept
          </v-btn>
          <v-btn
            x-small
            v-if="hasComponentPermission('LM_UPDATEPAYMENTSCUSTOMERAPPROVALSTATUS') && !item.forVendor"
            v-on:click="changePaymentStatusConfirmation(item.id, false)"
            type="error"
            title="Reject"
          >
            <v-icon color="error">{{ icons.mdiCloseCircleOutline }}</v-icon> &nbsp;Reject
          </v-btn>
          <v-btn x-small v-if="hasComponentPermission('LM_DELETEPAYMENT') && item.forVendor" text v-on:click="cancelInvoice(item.id)">
            Delete
          </v-btn>
          <!--  <v-btn x-small v-on:click="editPayment(item.id)" title="Edit Payment">
              <v-icon>{{ icons.mdiCircleEditOutline }}</v-icon>
            </v-btn>
            <v-btn x-small v-on:click="deletePaymentConfirmation(item.id)" title="Delete Payment">
              <v-icon>{{ icons.mdiDeleteCircleOutline }}</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              small
              outlined
              v-on:click="showTransactions(item.sellerInvoiceNo)"
              title="Show transactons"
              ><v-icon>{{ icons.mdiFormatListChecks }}</v-icon>
            </v-btn> -->
        </v-btn-toggle>
      </template>
    </v-data-table>

    <v-dialog v-model="showDetailsDialog" max-width="600px">
      <v-card>
        <v-card-title class="text-h5"> Payment Details </v-card-title>

          <v-card-text>
            <v-row no-gutters v-if="!showDetailsLoading">
              <v-col sm="12">
                <v-list>
                  <v-list-item> Invoice No: {{ paymentDetails.sellerInvoiceNo }} </v-list-item>
                  <v-list-item>
                    Customer: {{ paymentDetails.customer ? paymentDetails.customer.companyName : '' }}
                  </v-list-item>
                  <v-list-item> Vendor: {{ paymentDetails.vendor ? paymentDetails.vendor.companyName : '' }} </v-list-item>
                  <v-list-item> Frequency: {{ paymentDetails.frequency ? paymentDetails.frequency : '' }} </v-list-item>
               
                  <v-list-item v-if="paymentDetails.metaData">
                    Total Amount: {{ paymentDetails.metaData.totalAmount.amount | format_money(paymentDetails.metaData.totalAmount.currency)
                    }}
                  </v-list-item>
                  <v-list-item v-if="paymentDetails.metaData">
                    No Of Installments: {{ paymentDetails.metaData.noOfInstallments }}
                  </v-list-item>
                  <v-list-item v-if="paymentDetails.metaData">
                    Amount per Installment:
                    {{ paymentDetails.amountPerTransaction.amount | format_money(paymentDetails.amountPerTransaction.currency)}}
                  </v-list-item>
                  <!-- <v-list-item> Mandate: {{ paymentDetails.sellerInvoiceNo }} </v-list-item> -->
                </v-list>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12" class="text-center"
                ><v-progress-circular :width="3" indeterminate></v-progress-circular> Loading</v-col
              >
            </v-row>
            <v-row  v-if="!showTransactionsLoading && transactions && transactions.items && transactions.items.length">
              <v-col cols="12">
                <!-- <AddTransactionForm @transactionDetailsChanged="getData" /> -->
                <v-simple-table :key="GenerateRandomKey(transactions.items.length) +'dtkey'" dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center">
                          Due Date
                        </th>
                        <th class="text-center">
                          Amount
                        </th>
                        <th class="text-center">
                          Status
                        </th>
                        <!-- <th class="text-left">
                          Action
                        </th> -->
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in transactions.items" :key="item.code">
                        <td class="text-center">
                          {{ item.transactionAt | format_date_local_timezone }}
                        </td>
                        <td class="text-right">{{ item.amount.amount | format_money(item.amount.currency)}}</td>
                        <td class="text-center">
                          {{ item.status }}
                        </td>
                        <!-- <td class="text-left">
                          <v-btn :disabled="item.status != 'Not Issued'">Capture</v-btn>
                        </td> -->
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12" class="text-center">
                <v-progress-circular :width="3" indeterminate></v-progress-circular> Loading
              </v-col>
            </v-row>
          </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="showDetailsDialog = false"> Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbarConfirmation" vertical :timeout="10000">
      Are You sure, Do you want to Accept payment of Invoice: {{ paymentToChange.sellerInvoiceNo }}

      <template v-slot:action="{ attrs }">
        <v-btn color="success" v-if="paymentToChange.isApproved" @click="changePaymentStatus(paymentToChange.id, true)"
          >Sure, Accept
        </v-btn>
        <v-btn color="error" v-else @click="changePaymentStatus(paymentToChange.id, false)">Sure, Reject </v-btn>
        <v-btn text v-bind="attrs" @click="snackbarConfirmation = false">
          Cancel
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mdiCloseCircleOutline, mdiCheckboxMarkedCircleOutline } from '@mdi/js'
import Util from '@/utils/axios-util.js'
import { ref, reactive } from '@vue/composition-api'
import AddTransactionForm from '@/views/transaction/AddTransactionForm'

export default {
  data() {
    return {
      options: {},
    }
  },
  setup() {
    const loading = ref(false)
    const showDetailsDialog = ref(false)
    const showDetailsLoading = ref(false)
    const payments = reactive({})
    const deleteObj = reactive({})
    return {
      headers: [
        { text: 'Invoice No', value: 'sellerInvoiceNo', sortable: true },
        { text: 'Vendor', value: 'vendor.name', sortable: false },
        {
          text: 'Amount',
          value: 'metaData.totalAmount.amount',
          sortable: false,
          class: 'text-end',
          cellClass: 'text-end',
        },
        { text: '', value: 'action' },
      ],
      paymentDtKey: 'asdasd',
      payments,
      loading,
      // icons
      icons: {
        mdiCheckboxMarkedCircleOutline,
        mdiCloseCircleOutline,
      },
      showDetailsDialog,
      deleteObj,
      showDetailsLoading,
      showTransactionsLoading: false,
      transactions: [],
      paymentDetails: {},
      paymentToChange: {},
      snackbarConfirmation: false,
    }
  },
  mounted() {
    let e = this
    // e.getData()
  },
  watch: {
    options: {
      handler(newVal) {
        this.getData(false)
      },
      deep: true,
    },
  },
  components: {
    AddTransactionForm,
  },
  methods: {
    getData: function(updateKey = true) {
      let e = this
      e.loading = true
      e.showDetailsDialog = false
      let sortOrder = e.options.sortDesc ? 'DESC' : 'ASC'
      Util.Axios.get('/payment/get-paged-pending-payments', {
        params: {
          FromPageNumber: 1,
          PageNumber: e.options.page,
          PageSize: e.options.itemsPerPage,
          SearchText: '',
          SortOrder: e.options.sortBy && e.options.sortBy.length ? `${e.options.sortBy[0]}|${sortOrder}` : '',
          CustomerApprovalStatuses: ['Pending'],
        },
      })
        .then(function(response) {
          e.payments = response.data.data
          if(updateKey) e.paymentDtKey = e.GenerateRandomKey(4)
        })
        .catch(function(response) {})
        .finally(function(response) {
          e.loading = false
        })
    },
    changePaymentStatusConfirmation(id, isApproved) {
      let e = this
      Util.Axios.get(`/Payment/${id}/get-details`).then(function(response) {
        if (response.data.succeeded) {
          e.paymentToChange = response.data.data
          e.paymentToChange.isApproved = isApproved
          e.snackbarConfirmation = true
        } else {
          e.errorMessage(e.generateErrorMessage(response.data.errors))
        }
      })
    },
    changePaymentStatus: function() {
      let e = this

      e.loading = true
      Util.Axios.post(`/Payment/update-approval-status`, {
        paymentIds: [e.paymentToChange.id],
        status: e.paymentToChange.isApproved ? 'Accepted' : 'Rejected',
      })
        .then(function(response) {
          if (response.data.succeeded) {
            e.paymentToChange = {}
            e.snackbarConfirmation = false
            e.successMessage(response.data.message)
            e.getData()
          } else {
            e.errorMessage(e.generateErrorMessage(response.data.errors))
          }
        })
        .catch(function(response) {})
        .finally(function(response) {
          e.loading = true
        })
    },
    showDetails: function(paymentId) {
      let e = this
      if (paymentId) {
        e.paymentDetails = {}
        e.showDetailsLoading = true
        // get payment details
        Util.Axios.get(`/Payment/${paymentId}/get-details`)
          .then(function(response) {
            if (response.data.succeeded) {
              e.paymentDetails = response.data.data
              e.transactions.items = response.data.data.derivedTransactions
              e.showDetailsDialog = true
            } else {
              e.errorMessage(e.generateErrorMessage(response.data.errors))
            }
          })
          .catch(function(response) {})
          .finally(function(response) {
            e.showDetailsLoading = false
            e.showTransactionsLoading = false
          })
      } else {
        e.errorMessage('Invalid Payment selected.')
      }
    },
    cancelInvoice:function(invoiceId){
      let e = this
      if (!invoiceId) {
        e.errorMessage('Invalid invoice selected.')
        return false
      }
      e.loading = true
      Util.Axios.delete(`/payment/${invoiceId}/delete`)
        .then(function(response) {
          if (response.data.succeeded) {
            e.getData()
          } else e.errorMessage('Something went wrong!')
        })
        .catch(function(response) {})
        .finally(function(response) {
          e.loading = false
        })
    }
  },
}
</script>
